import React, {useState} from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import {Link} from "gatsby";
import Logo from "../../images/getgud-logo.svg"
import ContactUs from "./contact-us";

export default function Footer() {
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  const [showContactUs, setShowContactUs] = useState(false);
  return (<>
    <ContactUs showContactUs={showContactUs} setShowContactUs={setShowContactUs} />
    <footer className="font-white">
      <Container>
        <Row>
          <Col xs={12} lg={3}>
            <a href="/">
              <img src={Logo} alt="logo" className="footer-logo mb-5" />
            </a>
          </Col>
          <Col xs={12} lg={8} className="ml-auto">
            <Row>
              <Col xs={12} lg={4}>
                <div className="title">
                  {"Company"}
                </div>
                <Link to="/?section=about">
                  <Button variant="footer">
                    {"About"}
                  </Button>
                </Link>
                <Link to="/?section=why">
                  <Button variant="footer">
                    {"Features"}
                  </Button>
                </Link>
              </Col>

              <Col xs={12} lg={4}>
                <div className="title">
                  {"Plans"}
                </div>
                <Link to="/?section=plans">
                  <Button variant="footer">
                    {"Not-so-basic plan"}
                  </Button>
                </Link>
                <Link to="/?section=plans">
                  <Button variant="footer">
                    {"All-rounder-plan"}
                  </Button>
                </Link>
                <Link to="/?section=plans">
                  <Button variant="footer">
                    {"Power Plan"}
                  </Button>
                </Link>
                <Link to="/?section=plans">
                  <Button variant="footer">
                    {"Gigabit Plan"}
                  </Button>
                </Link>
              </Col>
              <Col xs={12} lg={4}>
                <div className="title">
                  {"Support"}
                </div>
                <a target="_blank" href={"mailto:support@getgud.gg"}>
                  <Button variant="footer">
                    {"Email: support@getgud.gg"}
                  </Button>
                </a>

                <a target="_blank" href={"tel:+61272534400"}>
                  <Button variant="footer">
                    {"Tel: 02-7253-4400"}
                  </Button>
                </a>
                <a target="_blank" href="/pdfs/GetGud-Terms-And-Conditions.pdf">
                  <Button variant="footer">
                    {"Terms & Conditions"}
                  </Button>
                </a>
                <a target="_blank" href="/pdfs/GetGud-Critical-Information-Summary.pdf">
                  <Button variant="footer">
                    {"Critical Information Summary"}
                  </Button>
                </a>
                <a target="_blank" href="/esafety">
                  <Button variant="footer">
                    {"eSafety"}
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  </>);
}